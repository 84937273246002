.App {
  text-align: center;
  height: 100%;
}

@font-face {
  font-family: "NanumSquareB";
  src: url(./fonts/NanumSquareB.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareEB";
  src: url(./fonts/NanumSquareEB.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareL";
  src: url(./fonts/NanumSquareL.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareR";
  src: url(./fonts/NanumSquareR.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareRoundB";
  src: url(./fonts/NanumSquareRoundB.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareRoundEB";
  src: url(./fonts/NanumSquareRoundEB.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareRoundL";
  src: url(./fonts/NanumSquareRoundL.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareRoundR";
  src: url(./fonts/NanumSquareRoundR.ttf) format("truetype");
}
